import React from 'react';

import window from '../utils/window';
import {
  InformativePage,
  SectionDescriptionColorfulText,
  SectionSmallDescriptionColorfulText,
  SectionSmallDescriptionText,
  SectionDescriptionText,
  FaqPanelDescriptionColorful,
  FaqPanelDescription,
  FaqOrderedListItemDescription,
  FaqOrderedListItemDescriptionColorful,
} from '../components/company-informative/InformativePage';
import { config } from '../config';
import { openExternalLink } from '../utils/openExternalLink';

import car from '../images/marginalia-autopilot-vehicle.svg';
import carBackground from '../images/car_background.svg';
import section1Img from '../images/marginalia-list-is-empty.svg';
import section2Img from '../images/marginalia-teamwork-1.svg';
import section3Img from '../images/marginalia-uploading.svg';

const handleProviderFormClick = () => openExternalLink(config.providerFormUrl);
const handleGooglePlayClick = () => openExternalLink(config.googlePlayUrl);
const handleAppStoreClick = () => openExternalLink(config.appStoreUrl);

const data = {
  passwordToEnter: '',
  companyName: 'Lyft',
  passwordPageImage: car,
  topSectionTitle: 'Welcome to Modern Health',
  topSectionDescription:
    'Lyft is excited to partner with Modern Health to give you access to holistic mental health services. Modern Health can provide you technology and professional support to help you reduce stress, feel more engaged, and be happier.',
  topSectionImage: carBackground,
  benefits: [
    {
      title: 'Certified Coaches & Therapists',
      description: (
        <SectionDescriptionText>
          Match with an expert who can support you with whatever you need whether that be coaching
          or therapy. All Lyft employees will have up to{' '}
          <SectionDescriptionColorfulText>
            15 coaching visits and 12 therapy visits
          </SectionDescriptionColorfulText>{' '}
          covered plus unlimited chat.
        </SectionDescriptionText>
      ),
      smallDescription: (
        <SectionSmallDescriptionText>
          Those with existing Lyra provider relationships will have the option to continue care.
          Please fill out{' '}
          <SectionSmallDescriptionColorfulText onClick={handleProviderFormClick}>
            this form
          </SectionSmallDescriptionColorfulText>{' '}
          and a member of the Modern Health team will reach out.
        </SectionSmallDescriptionText>
      ),
      image: section1Img,
    },
    {
      title: 'Self-Assessments & Digital Programs',
      description:
        'Our online courses combine best practices from scientifically-validated treatments that work.',
      image: section2Img,
      reverse: true,
    },
    {
      title: 'Audio Therapy',
      description:
        'Employees are matched to professional support based on what they want to work on most.',
      image: section3Img,
    },
  ],
  faq: [
    {
      title: 'What is Modern Health?',
      description:
        'Modern Health provides technology and professional support to help people reduce stress, feel more engaged, and be happier.',
    },
    {
      title: 'Why Modern Health?',
      description:
        'Modern Health’s mission is to change how people think about mental health; just like physical health, mental health impacts all of us. Whether you want to proactively reduce stress or treat depression, Modern Health will guide you to the right solution tailored to your needs. In other words, Modern Health aims to help people get easier access to relevant mental health care.',
    },
    {
      title: 'What can Modern Health help me with?',
      description:
        'Modern Health helps you cultivate the resilience needed to weather the ups and downs of everyday life by proactively offering support in the below areas and more.',
      unorderedList: [
        {
          title: 'Work Performance:',
          description:
            'Productivity, Leadership Skills, Work Relationships, Professional Development',
        },
        {
          title: 'Relationships:',
          description: 'Romantic Relationships & Dating, Family, Friends, Breakups',
        },
        {
          title: 'Stress & Anxiety:',
          description: 'Anxiety, Depression, Stress, Resilience',
        },
        {
          title: 'Healthy Lifestyles:',
          description: 'Sleep, Physical Activity, Eating Well, Habits',
        },
        {
          title: 'Diversity & Inclusion:',
          description: 'Gender, Equality, Unconscious Bias, LGBTQ',
        },
        {
          title: 'Life Challenges:',
          description: 'Pregnancy/Parenting, Elder/Child Care, Loss of A Loved One, Illness',
        },
        {
          title: 'Mindfulness & Meditation:',
          description: 'Stress Less, Sleep Better, Focus Better, Meditations for Beginners',
        },
      ],
    },
    {
      title: 'How do I register?',
      orderedList: [
        {
          description: (
            <FaqOrderedListItemDescription>
              Starting January 1, 2020, you can download the Modern Health app in the{' '}
              <FaqOrderedListItemDescriptionColorful onClick={handleGooglePlayClick}>
                Google Play Store (Android)
              </FaqOrderedListItemDescriptionColorful>{' '}
              or{' '}
              <FaqOrderedListItemDescriptionColorful onClick={handleAppStoreClick}>
                App Store (iOS).
              </FaqOrderedListItemDescriptionColorful>{' '}
              After your download is complete, select “Join Now” from the welcome page of the mobile
              app.
            </FaqOrderedListItemDescription>
          ),
        },
        {
          description: 'Use the first and last name you have on file with your employer.',
        },
        {
          description: 'Verify using your company email.',
        },
        {
          description: 'Enter your company email and password of your choice.',
        },
        {
          description:
            'Select “Register” on the web or “Agree & Join” on the mobile app to complete registration.',
        },
      ],
      secondDescription: (
        <FaqPanelDescription>
          If you have trouble registering for Modern Health, please don’t hesitate to reach out to{' '}
          <FaqPanelDescriptionColorful
            onClick={() => {
              window.location.href = 'mailto:help@joinmodernhealth.com';
            }}
          >
            help@joinmodernhealth.com
          </FaqPanelDescriptionColorful>{' '}
          with a note or screenshot. The Modern Health customer support team will verify the
          information against what they have on file with your employer to provide you the best
          instructions on how to successfully access Modern Health.
        </FaqPanelDescription>
      ),
    },
    {
      title: 'I have a Lyra provider that I enjoy seeing. Can I keep seeing this provider?',
      description: (
        <FaqPanelDescription>
          Modern Health has contracted with hundreds of high quality providers throughout the world.
          In fact, many Lyra providers are also contracted through the Modern Health platform
          allowing you to continue seeing your provider. However, if your provider is not yet
          contracted with Modern Health, we will coordinate with your provider in an effort to cover
          your visits up to the session limit for the next year. To ensure continuity of coverage,
          please fill out{' '}
          <FaqPanelDescriptionColorful onClick={handleProviderFormClick}>
            this form
          </FaqPanelDescriptionColorful>{' '}
          identifying your provider.
        </FaqPanelDescription>
      ),
    },
  ],
};

const LyftPage = () => <InformativePage data={data} />;

export default LyftPage;
